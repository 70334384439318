import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/gitlab-runner/.cache/bazel/_bazel_gitlab-runner/bae7e7ad93c0743178821acb11700900/sandbox/linux-sandbox/7783/execroot/_main/bazel-out/k8-fastbuild/bin/node_modules/.aspect_rules_js/next@15.0.1_277539520/node_modules/next/dist/lib/metadata/metadata-boundary.js");
